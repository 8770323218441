import React, { useState } from 'react';
import { addPolygon } from '../../../APIs/apiCalls';
import { NotificationManager } from 'react-notifications';
import toGeoJSON from 'togeojson';
import * as turf from '@turf/turf';
import { convertCoords } from '../../../Utils/commonFunctions';

const KMLFileUpload = ({farmer}) => {
    
   
    const [coordinates, setCoordinates] = useState([]);
    const [disabled, setDisabled] = useState(false);
    const [polygonId, setPolygonId] = useState(undefined);
    const [polygons, setPolygons] = useState([]);

    function savePolygon(){

        if(polygons.length === 0){
            NotificationManager.error("Either your KML File is invalid or not uploaded")
            return;
        }
        
        const totalArea = polygons.reduce((accumulator, currentValue) => {
            return accumulator + currentValue.area;
        }, 0);
        const totalPerimeter = polygons.reduce((accumulator, currentValue) => {
            return accumulator + currentValue.perimeter;
        }, 0);

        const obj = {};
        obj['points'] = coordinates;
        obj['polygon'] = polygons;
        obj['points'] = polygons.map(polygon => polygon.points);
        obj['farmerId'] = farmer._id;
        obj['farmername'] = farmer.firstname + ' ' + farmer.lastname;
        obj['tehsil'] = farmer.tehsil;
        obj['block'] = farmer.block;
        obj['district'] = farmer.district;
        obj['village'] = farmer.village;
        obj['area'] = totalArea;
        obj['mode'] = 'map';
        obj['type'] = 'upload-kml';
        obj['perimeter'] = totalPerimeter;

        setDisabled(true);
        addPolygon(obj).then(async (response) => {
            setDisabled(false)
           
            if(response.result === 'success') {
                setPolygonId(response.data)
                
                NotificationManager.success("Your Map has been saved to the list");
            }
            else if(response.error){
                NotificationManager.error(response.error)
            }
        }).catch((error) => {
            NotificationManager.error("Internal Server Error")
        })
    }
    
    // Function to calculate area and perimeter from a GeoJSON object
     const calculateAreaAndPerimeterFromGeoJSON = (geoJsonObject) => {
        let polygon = [];
        const {features} = geoJsonObject;

        for(let feature of features){
            const coordinates = feature.geometry.coordinates;
            
            const polygon_ = turf.polygon(coordinates); // Defines geometry of the coordinates
    
            const area = turf.area(polygon_); // Calc area
    
            const perimeter = turf.length(polygon_); // Calc perimeter

            const convertedPoints = convertCoords(coordinates[0]);  //convert an array of [longitude, latitude] pairs to [latitude, longitude]

            polygon.push({area, perimeter, points : convertedPoints});
        }

        setPolygons(polygon);
    };

    const fileChangeHandler = (event) => {
        
        const file = event.target.files[0];

        if(file ){
            if(file.name.includes('.kml') === true){
                setCoordinates([]);
                const reader = new FileReader();
    
                reader.onload = function(e) {
                    const kmlString = e.target.result;
                    const geoJsonObject = parseKML(kmlString);
                    calculateAreaAndPerimeterFromGeoJSON(geoJsonObject);
                };
    
                reader.readAsText(file);
            }
            else{
                NotificationManager.error("KML file is not valid")
            }
        }
        
    }

    // Function to convert KML to GeoJSON and extract coordinates
    const parseKML = (kmlString) => {
        const parser = new DOMParser();
        const kml = parser.parseFromString(kmlString, 'application/xml');
        const converted = toGeoJSON.kml(kml);
        return converted;
    };
    // function parseKML(kmlData) {
    //     const parser = new DOMParser();
    //     const xmlDoc = parser.parseFromString(kmlData, 'text/xml');
        
    //     // Now you can access and manipulate the XML DOM as needed
    //     // For example:
    //     const placemarks = xmlDoc.getElementsByTagName('Placemark');
    //     for (let i = 0; i < placemarks.length; i++) {
    //         const name = placemarks[i].getElementsByTagName('name')[0].textContent;
    //         const coordinates = placemarks[i].getElementsByTagName('coordinates')[0].textContent;
    //         let coordinates_ = [];
    //         const coordsArray = coordinates.split(' ');
    //         for(let i=0;  i<coordsArray.length; i++){
                
    //             if(coordsArray[i] != ''){
    //                 let coords = coordsArray[i].split(',');
    //                 coords[0] = parseFloat(coords[0]);
    //                 coords[1] = parseFloat(coords[1]);
    //                 coordinates_.push([coords[1], coords[0]]);
    //             }
    //         }
    //         console.log(coordinates_);
    //         setCoordinates((prev) => [...prev, coordinates_]);
    //     }
    // }

    return (
       
        <div className='map-container'>
            <h5>Upload KML File </h5>
            <input className="form-control" placeholder="Please upload KML File" type="file"
            onChange={(event) => fileChangeHandler(event)}
            />
            <div>
                <button className='mt-2 btn btn-sm btn-bluish-green' onClick={() => savePolygon()} disabled={disabled}>Upload</button> &nbsp;
                {
                    polygonId === undefined ? null : <a className='mt-2 btn btn-sm btn-bluish-green' href={`/#/view/${polygonId}`} target='_blank'>View KML File <i className='typcn typcn-export'></i> </a>
                }
                
            </div>
        </div>

    )
}

export default KMLFileUpload