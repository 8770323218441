import Layout from "../../../Layout";
import ManageMembersTable from "./ManageMembersTable/ManageMembersTable";

const ManageMembers = () => {
    return (
        <>
            <div className="col-12 grid-margin">
                <div className="card">
                    <div className="card-body">
                        <h4>Users Table</h4>
                        <ManageMembersTable/>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ManageMembers;