import { PDFDownloadLink } from "@react-pdf/renderer";
import PDFContent from "../Land Records/AddLandRecords/LandRecordForm/LeaseAgreementDownload/PDFContent";
import { downloadFile } from "../../Utils/commonFunctions";
import PDFContentU from "../Land Records/AddLandRecords/LandRecordForm/Undertaking/PDFContentU";

const LandRecords = ({landrecord, data, undertaking, leasedAgreement}) => {
    return (
        <div className="card" style={{fontStyle : 'italic', textTransform : 'capitalize'}}>
            <div className="row">
                <div className="col-md-12 mt-3">
                    <div className={`p-3`}>
                        <h5 className="text-info">Land Documents.</h5>
                        {
                            landrecord ? 
                            <div className="mt-3 mb-3">
                            <h6 style={{textDecoration : 'underline'}}>
                                Leased Documents
                            </h6>
                            <p>Leased Land Area : {landrecord.leasedLandArea}</p>
                            <p>Leased Land Khasra Numbers : {landrecord.leasedLandKhasraNumbers.join(', ')}</p>
                            <div>
                                {
                                    landrecord.leasedDocuments.map((url, index) => 
                                        <div  className="my-1" key={index}>
                                            <a className="btn btn-sm btn-info" target="_blank" href={url}>View</a> &nbsp;
                                            <button className="btn btn-sm btn-info" onClick={() => downloadFile(url)}>Download</button>
                                        </div>
                                    )
                                }
                            </div>
                            <hr/>
                            <h6 style={{textDecoration : 'underline'}}>Owned Documents</h6>
                            <p>Owned Land Area : {landrecord.ownedLandArea}</p>
                            <p>Owned Land Khasra Numbers : {landrecord.ownedLandKhasraNumbers.join(', ')}</p>
                            <div>
                                {
                                    landrecord.ownedDocuments.map((url, index) => 
                                    <div  className="my-1" key={index}>
                                        <a className="btn btn-sm btn-info" target="_blank" href={url}>View</a> &nbsp;
                                        <button className="btn btn-sm btn-info" onClick={() => downloadFile(url)}>Download</button>
                                    </div>
                                    )
                                }
                            </div>
                        </div> : <em>No Land Records are uploaded</em>
                        }
                    </div>
                </div>
                <div className="col-md-12 mt-3">
                    <div className=" p-3">
                        <h5 className="text-info">Undertaking And Leased Documents Records.</h5>
                        {
                            undertaking && undertaking.length>0 ? <div  style={{backgroundColor : '#F8F8F8', marginTop : '10px', padding : '10px'}}> 
                                <h6 style={{textDecoration : 'underline'}}>Undertaking Document</h6>
                                <p>Status : <span className='badge bg-success text-white'>UPLOADED  <i className='typcn typcn-input-checked'></i> </span></p>
                                {
                                    undertaking.map((item, index) => 
                                        <div key={index}>
                                            <p>{index+1} . Khasra Numbers : {item.khasraNumbers.join(', ')}, Area : {item.area} Acres &nbsp;
                                            </p>
                                            <p>
                                                <PDFDownloadLink document={<PDFContentU undertaking = {item} />} fileName={`${item.farmerName}_${item.farmerVillage}_${item.farmerBlock}_${item.farmerTehsil}_${item.farmerDistrict}.pdf`}>
                                                        {
                                                            ({ blob, url, loading, error }) => loading ? 'Loading document...' : <button className="btn btn-sm btn-primary"><i className="typcn typcn-download btn-icon-prepend"></i> </button> 
                                                        }
                                                </PDFDownloadLink>
                                            </p>
                                        </div>
                                    )
                                }
                                
                            </div> : <p className='text-danger mt-3'><i className='typcn typcn-warning'></i>  Undertaking Agreement is not uploaded.</p>
                        }

                        {
                            leasedAgreement && leasedAgreement.length > 0 ? <div  style={{backgroundColor : '#F8F8F8', marginTop : '10px', padding : '10px'}}> 
                                <h6 style={{textDecoration : 'underline'}} >Leased Document</h6>
                                <p>Status : <span className='badge bg-success text-white'>UPLOADED  <i className='typcn typcn-input-checked'></i> </span></p>
                                {
                                    leasedAgreement.map((item, index) => 
                                    <div>
                                        <p>{index+1} . Khasra Numbers : {item.khasraNumbers.join(', ')}, Area : {item.area} Acres &nbsp;
                                        
                                        <PDFDownloadLink key={index} document={<PDFContent leasedAgreement = {item} />} fileName={`${item.farmerName}_${item.farmerVillage}_${item.farmerBlock}_${item.farmerTehsil}_${item.farmerDistrict}.pdf`}>
                                                {
                                                    ({ blob, url, loading, error }) => loading ? 'Loading document...' : <button className="btn btn-sm btn-primary"><i className="typcn typcn-download btn-icon-prepend"></i> </button> 
                                                }
                                        </PDFDownloadLink> </p> 
                                        {/* &nbsp;
                                        <button className='btn btn-sm btn-success' disabled={disabled2}><i className="typcn typcn-edit btn-icon-prepend"></i> Edit</button> */}
                                    
                                        <hr/>
                                    </div>)
                                }
                            
                            </div> : <p className='text-danger mt-3'><i className='typcn typcn-warning'></i>  Leased Agreement is not uploaded.</p>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LandRecords;