import { useEffect, useState } from 'react';
import styles from './Dashboard.module.css';
import YearWiseFarmer from './YearWiseFarmer';
import ClipLoader from 'react-spinners/ClipLoader';
const StatusRecords = ({data, isAWDFarmer}) => {

    useEffect(() => {
        (async() => {
            
           
        })();
    },[]);
    return (
        <div>
            
            
            <div className="dashboard-cards mt-1">

                <div className="dashboard-card card-yellow grid-margin stretch-card">
                    <div className="d-icons card-icon card-yellow-dark">
                        <span className="vertical-center"><i className="typcn typcn-chart-line"></i></span>
                    </div>
                    <div className="pl-3 card-text">
                        <p>FARMERS ENROLLED </p>
                        <p>{isAWDFarmer === true ? data.awdFarmers : data.farmerCount}</p>
                        {/* <p>{isAWDFarmer === true ? '4000' : '50,700'}</p> */}
                    </div>
                    
                </div>
                <div className="dashboard-card card-d-green text-white grid-margin stretch-card">
                    <div className="d-icons card-icon card-d-green-dark">
                        <span className="vertical-center"><i  className="typcn typcn-chart-area"></i></span>
                    </div>
                    <div className="pl-3 card-text">
                        <p>AREA COVERED </p>
                        <p>{isAWDFarmer === true ? data.totalAWDArea : data.totalArea} ACRES</p>                                                                
                    </div>
                </div>
                {/* <div className="dashboard-card card-green text-white grid-margin stretch-card">
                    <div className="d-icons card-icon card-green-dark">
                        <span className="vertical-center"><i  className="typcn typcn-pipette"></i></span>
                    </div>
                    <div className="pl-3 card-text">
                        <p>Input Costs Reduction </p>
                        <p>32% Per Acre</p>
                    </div>
                </div>
                <div className="dashboard-card card-orange text-white grid-margin stretch-card">
                    <div className="d-icons card-icon card-orange-dark">
                        <span className="vertical-center"><i  className="typcn typcn-map"></i></span>
                    </div>
                    <div className="pl-3 card-text">
                        <p>Profit Increase </p>
                        <p>26% Per Acre</p>
                    </div>
                </div> */}
                <div className="dashboard-card card-green text-white grid-margin stretch-card">
                    <div className="d-icons card-icon card-green-dark">
                        <span className="vertical-center"><i  className="typcn typcn-pipette"></i></span>
                    </div>
                    <div className="pl-3 card-text">
                        <p>PIPES INSTALLED </p>
                        <p>{data.aeration[0]?.count || 'NA'}</p>
                        {/* <p>500 Pipes</p>   */}
                    </div>
                </div>
                <div className="dashboard-card card-orange text-white grid-margin stretch-card">
                    <div className="d-icons card-icon card-orange-dark">
                        <span className="vertical-center"><i className="typcn typcn-map"></i></span>
                    </div>
                    <div className="pl-3 card-text">
                        <p> POLYGONS DRAWN</p>
                        <p>{ data.polygonNumber }</p>

                        {/* <p>{isAWDFarmer === true ? 4200 : 35000}</p> */}
                        
                    </div>
                </div>
            </div>
           
            {
                isAWDFarmer === false ? <YearWiseFarmer data={data}/> : null
            } 
        </div>
    );

}

export default StatusRecords;

 {/* {
                isAWDFarmer === false ? <YearWiseFarmer farmers={farmers}/> : null
            } */}