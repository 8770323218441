import Layout from "../../../Layout";
import LandRecords from "./LandRecords/LandRecords";

const AddLandRecords = () => {
    
    
    return (
        <div className="col-12 grid-margin">
            <div className="card">
                <div className="card-body1">
                    <h4>Land Records</h4>
                    <LandRecords />
                </div>
            </div>
        </div>
    );
}

export default AddLandRecords;