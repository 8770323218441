import { dateDifferenceInDays } from "../../../../Utils/commonFunctions";

const validate = (values) => {
    const errors = {};
    const alphabeticRegex = new RegExp(/^[a-zA-Z]+$/);

    if(!values.crop){
        errors.crop = "Crop is Required";
    }
    else if(values.crop.toLowerCase() === 'other' && values.otherCrop === ''){
        errors.otherCrop = "Crop name is Required";
    }
    if(values.year===""){
        errors.year = "Year is required";
    }
    if(values.farmerId===""){
        errors.farmerId = "Farmer Name is required";
    }
    if(values.season===""){
        errors.season = "Season is required";
    }
    if(values.leasedLandArea === ""){
        errors.area = "Leased Land Area is Required";
    }
    if(values.ownedLandArea === ""){
        errors.area = "Owned Land Area is Required";
    }
    if(values.leasedLandArea && values.leasedLandArea<0){
        errors.leasedLandArea = "Area can't be negative";
    }
    if(values.ownedLandArea && values.ownedLandArea<0){
        errors.ownedLandArea = "Area can't be negative";
    }
    if(!values.variety){
        errors.variety = "Variety is Required";
    }

    if(values.landPreprationDate!='' && values.dateOfNursery >= values.landPreprationDate){
        errors.landPreprationDate = "Land Prepration Date should be more than Nursery Date."
    }

    if(values.expectedSowingDate!='' && values.landPreprationDate >= values.expectedSowingDate){
        errors.expectedSowingDate = "Expected sowing Date should be more than Land Prepration Date."
    }

    if(values.sowingDate!='' && values.expectedSowingDate > values.sowingDate){
        errors.sowingDate = "Sowing Date should be more than or equal to Expected Sowing Date."
    }

    if(values.expectedHarvestingDate!='' && values.sowingDate >= values.expectedHarvestingDate){
        errors.expectedHarvestingDate = "Expected Harvesting Date should be more than Sowing Date."
    }

    if(values.harvestingDate!='' && values.expectedHarvestingDate > values.harvestingDate){
        errors.harvestingDate = "Harvesting Date should be more than or equal to Expected Harvesting Date."
    }



   

    // yieldPerAcre
    // if(!values.majorIssues){
    //     errors.majorIssues = "Major Issues is Required"
    // }
    if(values.pesticideCost<0){
        errors.pesticideCost = "Cost can't be negative."
    }
    if(values.labourCost<0){
        errors.labourCost = "Cost can't be negative."
    }
    if(values.pesticideCost<0){
        errors.pesticideCost = "Cost can't be negative."
    }
    if(values.majorBuyers!=""&&alphabeticRegex.test(values.majorBuyers)===false){
        errors.majorBuyers = "String should contain alphabets."
    }
    if(values.fertilizerCost<0){
        errors.fertilizerCost = "Cost can't be negative."
    }
    if(values.otherCost<0){
        errors.otherCost = "Cost can't be negative."
    }
    if(values.totalCost<0){
        errors.totalCost = "Cost can't be negative."
    }
   
    if(values.yieldPerAcre<0){
        errors.yieldPerAcre = "Yeild can't be negative.";
    }
    if(values.revenue<0){
        errors.revenue = "Revenue can't be negative."
    }
    console.log(errors);
    return errors;
}

export default validate;