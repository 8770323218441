import { useEffect, useState } from "react";
import { getfarmerProfile, isTokenValid } from "../../APIs/apiCalls";
import { useParams } from "react-router-dom";

import AboutFarmer from "./AboutFarmer";
import styles from './Profile.module.css';
import Documents from "./Documents";
import Spinners1 from "../../Components/Spinners/Spinners1";
import CropDetails from "./CropDetails";
import Layout from "../../Layout";
import AerationDetails from "./AerationDetails";
import LandRecords from "./LandRecords";
import { projectList } from "../../ini/values";



const Profile = () => {
    const [data, setData] = useState(undefined);
    const [tabNumber, setTabNumber] = useState(1);
    const [user, setUser] = useState(undefined);

    const {id} = useParams();
    useEffect(() => {
      
        (async ()=>{
            let response = await getfarmerProfile(id);
            
            setData(response.data);
            
            const token = localStorage.getItem('token');
          
            response = await isTokenValid({token});
            
            setUser(response.data);
        })();
        
    }, [])
    if(typeof data === 'undefined' && user === undefined){
        return <Spinners1/>
    }
    return (
        <>
        <div className="col-12 grid-margin">
            <div className="card p-3" style={{backgroundColor : '#d1ded1', boxShadow : 'none'}}>
                <h4 className="m-0">{data.farmername.toUpperCase()}</h4>
            </div>
        </div>
        <nav className="col-md-12">
            <div className="nav nav-tabs" id="nav-tab" role="tablist"  style={{fontStyle : 'italic', textTransform : 'capitalize'}}>
                <button className={`nav-link ${tabNumber === 1 ? 'active' : ''} ${styles.navStyles}`} id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true" onClick={() => setTabNumber(1)}>About Farmer</button>
                <button className={`nav-link ${tabNumber === 3 ? 'active' : ''} ${styles.navStyles}`} id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false" onClick={() => setTabNumber(3)}>Crop Details</button>
                <button className={`nav-link ${tabNumber === 4 ? 'active' : ''} ${styles.navStyles}`} id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false" onClick={() => setTabNumber(4)}>Documents</button>
                {
                    data.projectName === projectList[0].value ? <button className={`nav-link ${tabNumber === 2 ? 'active' : ''} ${styles.navStyles}`} id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false" onClick={() => setTabNumber(2)}>Land Records</button> : null
                }
                {
                    data.projectName === projectList[0].value ? <button className={`nav-link ${tabNumber === 5 ? 'active' : ''} ${styles.navStyles}`} id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false" onClick={() => setTabNumber(5)}>Aeration</button> : null
                }
                
            </div>
        </nav>
        <div className="tab-content col-md-12" id="nav-tabContent">
            {
                tabNumber === 1 ?  
                <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                    <AboutFarmer data={data} />
                </div> : ''
            }
            {
                tabNumber === 2 ?  <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                    <LandRecords  data={data} landrecord={data.land} undertaking={data.undertakingDocument} leasedAgreement={data.leasedAgreement}/>
                </div> : ''
            }
            {
                tabNumber === 3 ?  <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                    <CropDetails data={data.crop} projects={user.projects}/>
                </div> : ''
            }
            {
                tabNumber === 4 ?  <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                    <Documents aadhar={data.aadhar} pan={data.pan} bank={data.bank}/>
                </div> : ''
            }
            {
                tabNumber === 5 ?  <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                    {
                        data.aeration!=undefined ? <AerationDetails data={data.aeration}/> : 'Number of pipes are not selected.'
                    }
                </div> : ''
            }
        </div>
        </>
    );
}
export default Profile;