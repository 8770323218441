import { useFormik } from "formik";
import styles from './../AddFeildInformation.module.css';
import { units } from "./dropdownOptions";
import { getTodaysDate } from "../../../../Utils/commonFunctions";
import WarningMessage from "../../../../Components/WarningMessage/WarningMessage";


const validate = (values) => {
    const errors = {};
    if(values.herbicide === ''){
        errors.herbicide = "This Feild is required"
    }
    if(values.herbicide === 'other' && values.otherHerbicide === ''){
        errors.otherHerbicide = "Herbicide is required"
    }
    if(values.dosage === ''){
        errors.dosage = "Dosage is required"
    }
    if(values.dosage<0){
        errors.dosage = "Dosage can't be negative"
    }
    if(values.date === ''){
        errors.date = "Date is required"
    }
    if(values.unit === ''){
        errors.unit = "Unit is required"
    }
    // console.log(errors)
    return errors;
}

const WeedManagement = ({change, herbicides}) => {
    // console.log(herbicides);
    const todaysDate = getTodaysDate();
    const formik = useFormik({
        initialValues : {
            herbicide : '',
            dosage : '',
            unit : '',
            date : '',
            otherHerbicide : ''
        },
        validate,
        onSubmit : (values) => {
            change('weeds', values);
            formik.resetForm({});
        }
    });
    const handleChange = (event) => {
        let {value} = event.target;
        formik.setFieldValue('herbicide', value);
        if(value!='other'){
            formik.setFieldValue('otherHerbicide', '');
            let idx = herbicides.findIndex(item => item.name === value);
            formik.setFieldValue('unit', herbicides[idx].unit);
        }else{
            formik.setFieldValue('unit', '');
        }
    }
    return (
        <div>
            <form>
                <div className="row">
                    <div className="col-md-3">
                        <label className="col-form-label">Name Of Herbicides Used</label>   
                        <select className={`form-control ${ formik.submitCount>0 && formik.errors.herbicide ? styles.formInputError : ""}`} 
                         id='herbicide' onChange={handleChange} onBlur={formik.handleBlur} value={formik.values.herbicide}>
                            <option value=''>Select</option> 
                            {
                                herbicides.map((herbicide, index) => <option key={index} value={herbicide.name}>{herbicide.name}</option>)
                            }
                            <option value='other'>Others</option> 
                        </select>
                        {formik.submitCount>0 && formik.errors.herbicide ? <div className={styles.error}>{formik.errors.herbicide}</div> : ""}
                    </div>
                    {
                        formik.values.herbicide === 'other' ? 
                        <div className="col-md-3">
                            <label className="col-form-label">Mention name of herbicide used</label>   
                            <input className={`form-control ${ formik.submitCount>0 && formik.errors.otherHerbicide ? styles.formInputError : ""}`} 
                            type="text" id='otherHerbicide' placeholder="Other herbicide Used" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.otherHerbicide}/>
                            {formik.submitCount>0 && formik.errors.otherHerbicide ? <div className={styles.error}>{formik.errors.otherHerbicide}</div> : ""}
                        </div>
                        : ''
                    }
                    
                    {
                        formik.values.herbicide != 'other' ? 
                        <div className="col-md-3">
                                <label className="col-form-label">Unit</label>   
                                <input type="text" 
                                placeholder="unit"
                                className={`form-control form-unit ${ formik.submitCount>0 && formik.errors.unit ? styles.formInputError : ""}`} 
                                disabled={true}
                                id='unit' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.unit}/>     
                                 {formik.submitCount>0 && formik.errors.unit ? <div className={styles.error}>{formik.errors.unit}</div> : ""} 
                        </div> : 
                        <div className="col-md-3">
                            <label className="col-form-label">Unit</label>   
                            <select type="text" 
                            className={`form-control form-unit ${ formik.submitCount>0 && formik.errors.unit ? styles.formInputError : ""}`} 
                            id='unit' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.unit}>
                                <option value=''>Select Unit</option>
                                {
                                    units.map((item) => <option value={item}>{item}</option>)
                                }
                            </select>  
                            {formik.submitCount>0 && formik.errors.unit ? <div className={styles.error}>{formik.errors.unit}</div> : ""}    
                        </div>
                    }
                    <div className="col-md-3">
                        <label className="col-form-label">Quantity Applied</label>   
                        <input  className={`form-control ${ formik.values.dosage<0 || (formik.submitCount>0 && formik.errors.dosage) ? styles.formInputError : ""}`}  type="number" id='dosage' placeholder="Dosage" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.dosage}/>
                        {formik.values.dosage<0 || (formik.submitCount>0 && formik.errors.dosage) ? <div className={styles.error}>{formik.errors.dosage}</div> : ""}
                       
                    </div>
                    
                    <div className="col-md-3">
                        <label className="col-form-label">Date of Application</label>   
                        <input max={todaysDate}  className={`form-control ${ formik.values.date<0 || (formik.submitCount>0 && formik.errors.date) ? styles.formInputError : ""}`}
                        type="date" id='date' placeholder="Date" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.date}/>
                        {formik.submitCount>0 && formik.errors.date ? <div className={styles.error}>{formik.errors.date}</div> : ""}    
                    </div>
                    
                </div>
                <div className="row col-md-4">
                    <button className="btn btn-secondary mt-2" type="button" onClick={formik.handleSubmit}>Add</button>
                </div>
                <WarningMessage/>
            </form>
        </div>
    ); 
}

export default WeedManagement;