import { useEffect, useState } from "react";
import Map_ from "./Map";
import LocationDeniedError from "../../../Components/LocationError/LocationDeniedError";
import { NotificationManager } from "react-notifications";
import KMLFileUpload from "./KMLFileUpload";
import FarmerSearchBar from "../../../Components/FarmerSearchBar/FarmerSearchBar";
import { useNavigate } from "react-router-dom";

const AddPolygonFile = () => {
    const [farmer, setFarmer] = useState({});
    const [type, setType] = useState('upload-kml');
    const [locationAllowed, setLocationAllowed] = useState(undefined);
    const [location, setLocation] = useState(null);
    const [finishStatus, setFinishStatus] = useState(false);
    
    const navigate = useNavigate();

    const alertUser = (event) => {
        event.preventDefault();
        event.returnValue = "Are you sure you want to leave? Changes you made may not be saved."; // Custom message may not work in all browsers
    };

    const onBackButtonEvent = (event) => {
        event.preventDefault();
        if (!finishStatus) {
            if (window.confirm("Do you want to go back to the dashboard?")) {
                setFinishStatus(true); // Confirm navigation
                navigate("/dashboard");
            } else {
                window.history.pushState(null, null, window.location.hash);
                setFinishStatus(false); // Stay on the page
            }
        }
    };

     

    useEffect(() => {
        window.history.pushState(null, null, window.location.hash);
        window.addEventListener('popstate', onBackButtonEvent);
        window.addEventListener('beforeunload', alertUser);

        return () => {
            window.removeEventListener('popstate', onBackButtonEvent);
            window.removeEventListener('beforeunload', alertUser);
        };
    }, [finishStatus]);




    const handleFilterValues = (event, type) => {
        setFarmer(event);
        setType(type);
    }
    
    useEffect(() => {
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition(
              (position) => {
                
                const { latitude, longitude, accuracy } = position.coords;
                console.log(latitude);
                console.log(longitude);
                setLocationAllowed(true);
                setLocation({ 
                        lat : latitude, 
                        lng : longitude 
                });
              },
              (error) => {
                setLocationAllowed(false);
                NotificationManager.error('User Denied Location Access', 'Error');
              },
              {
                enableHighAccuracy: true,
                timeout: 30000, // 30 seconds
                maximumAge: 0
              }
            );
          } else {
            NotificationManager.error('Geolocation is not available in this browser', 'Error');
          }
    }, [])

    return (
           
        <>
            { locationAllowed === false && <LocationDeniedError/>}
            <div className="col-12 grid-margin">
            { locationAllowed === true &&
                <div className="card">
                    <div className="card-body">
                        <FarmerSearchBar change={(farmer) => setFarmer(farmer)}/>
                    </div>
                </div>
            }

            {/* {
                farmer._id != undefined ? 
                <div className="card mt-2">
                    <div className="card-body">
                        <div className="col-md-3">
                            <label className="col-form-label">Select Type</label>
                            <select className="form-control" defaultValue='' onChange={(event) => setType(event.target.value)}>
                                <option value=''>Select type</option>
                                <option value='draw-kml' >Draw KML</option>
                                <option value='upload-kml' >Upload KML</option>
                            </select>
                        </div>     
                    </div>
                </div>: null
            } */}

            {
                    farmer._id != undefined && locationAllowed === true && <div className="card mt-2">
                    <div className="card-body">
                        <KMLFileUpload farmer={farmer}/>
                    </div>
                </div>
            }

            {/* {
                farmer._id != undefined && locationAllowed === true && type!='' ? 
                <div>
                    {
                        type === 'draw-kml' ? 
                        <div className="card mt-2">
                            <div className="card-body">
                                <Map_ farmer={farmer} location={location}/>
                            </div>
                        </div>
                        : 
                        <div className="card mt-2">
                            <div className="card-body">
                                <KMLFileUpload farmer={farmer}/>
                            </div>
                        </div>
                    }
                </div> : 
                null
            } */}
        </div>
    </>
    );
}

export default AddPolygonFile;