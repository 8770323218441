import {Route, HashRouter, Routes, createBrowserRouter, BrowserRouter, RouterProvider, createHashRouter } from "react-router-dom";
import SignIn from './Pages/SignIn/SignIn';
import AddOnboardingInformation from "./Pages/Onboarding/AddOnboardingInformation/AddOnboardingInformation";
import ManageOnboardingInformation from "./Pages/Onboarding/ManageOnboardingInformation/ManageOnboardingInformation";
import AddFeildInformation from "./Pages/FeildInformation/AddFeildInformation/AddFeildInformation";
import AddAeration from "./Pages/Aeration/AddAerationDetails/AddAerationDetails";
import ManageFeildInformation from "./Pages/FeildInformation/ManageFeildInformation/ManageFeildInformation";
import ManageAerationDetails from "./Pages/Aeration/ManageAerationDetails/ManageAerationDetails";
import Profile from "./Pages/Profile/Profile";
import AddLandRecords from "./Pages/Land Records/AddLandRecords/AddLandRecords";
import ManageLandRecords from "./Pages/Land Records/ManageLandRecords/ManageLandRecords";
import ManageMembers from "./Pages/FeildTeam/ManageMembers/ManageMembers";
import './App.css';
import 'chartjs-plugin-datalabels';

import AdminProfile from "./Pages/Admin/AdminProfile";
import AddPolygonFile from "./Pages/PolygonMapping/AddPolygonFile/AddPolygonFile";
import ManagePolygon from "./Pages/PolygonMapping/ManagePolygon/ManagePolygon";
import ViewPolygonFile from "./Pages/PolygonMapping/ViewPolygonFile/ViewPolygonFile";
import { NotificationContainer } from "react-notifications";
import ProtectedRoute from "./Routes/ProtectedRoute";
import TrackUsers from "./Pages/TrackUsers/TrackUsers";
import Inputs from "./Pages/AgroInputs/Inputs";
import Documents from "./Pages/Documents/Documents";
import ManageDocument from "./Pages/Documents/ManageDocument";
import ManageLocations from "./Pages/Location/ManageLocation/ManageLocations";
import Dashboard from "./Pages/Dashboard/Dashboard";
import DownloadKMLs from "./Pages/PolygonMapping/DownloadKMLs/DownloadKMLs";
import Layout from "./Layout";

function App() {

  const router = createHashRouter([
    {
      path : '/',
      element : <SignIn/>
    },
    {
      path : '/signin',
      element : <SignIn/>
    },
    {
      path : '/view/:id/:index',
      element : <ProtectedRoute><ViewPolygonFile/></ProtectedRoute>
    },
    {
      path : '/',
      element : <ProtectedRoute><Layout/></ProtectedRoute>,
      children : [
        {
          path : '/dashboard',
          element : <Dashboard/>
        },
        {
          path : '/add-onboarding',
          element : <AddOnboardingInformation/>
        },
        {
          path : '/update/:id',
          element : <AddOnboardingInformation/>
        },
        {
          path : '/manage-onboarding',
          element : <ManageOnboardingInformation/>
        },
        {
          path : '/add-landrecords',
          element : <AddLandRecords/>
        },
        {
          path : '/manage-landrecords',
          element : <ManageLandRecords/>
        },
        {
          path : '/add-feild-information',
          element : <AddFeildInformation/>
        },
        {
          path : '/update-feild-information/:id',
          element : <AddFeildInformation/>
        },
        {
          path : '/manage-feild-information',
          element : <ManageFeildInformation/>
        },
        {
          path : '/add-aeration',
          element : <AddAeration/>
        },
        {
          path : '/manage-aeration',
          element : <ManageAerationDetails/>
        },
        {
          path : '/manage-members',
          element : <ManageMembers/>
        },
        {
          path : '/farmer-profile/:id',
          element : <Profile/>
        },
        {
          path : '/admin-profile',
          element : <AdminProfile/>
        },
        {
          path : '/polygon-mapping',
          element : <AddPolygonFile/>
        },
        {
          path : '/manage-polygon-mapping',
          element : <ManagePolygon/>
        },
        
        {
          path : "/track",
          element : <TrackUsers/>
        },
        {
          path : "/approvals",
          element : <Inputs/>
        },
        {
          path : "/manage-location",
          element : <ManageLocations/>
        },
        {
          path : "/manage-location",
          element : <ManageLocations/>
        },
        {
          path : "/documents",
          element : <Documents/>
        },
        {
          path : "/manage-documents",
          element : <ManageDocument/>
        },
      ]
    },

  ])
  return (
    <>
      <RouterProvider router={router}/>
      <NotificationContainer/>
    </>
    
  );
}

export default App;

 // <HashRouter>
    //   <Routes>
    //     <Route element= {<SignIn/>} path="/" />
    //     <Route element= {<SignIn/>} path="/signin"/>
    //     {/* <Route element= {<ErrorPage/>} path="/*" /> */}
    //     <Route element= {<ProtectedRoute><ViewPolygonFile /></ProtectedRoute>} path="/view/:id" />
    //   </Routes>
      
    //   <Routes>
    //     <Route element= {<ProtectedRoute><Dashboard/></ProtectedRoute>} path="/dashboard"/>
    //     <Route element= {<ProtectedRoute><AddOnboardingInformation/></ProtectedRoute>} path="/add-onboarding"/>
    //     <Route element= {<ProtectedRoute><AddOnboardingInformation/></ProtectedRoute>} path="/update/:id"/>
    //     <Route element= {<ProtectedRoute><ManageOnboardingInformation/></ProtectedRoute>} path="/manage-onboarding"/>
    //     <Route element= {<ProtectedRoute><AddLandRecords/></ProtectedRoute>} path="/add-landrecords"/>
    //     <Route element= {<ProtectedRoute><ManageLandRecords/></ProtectedRoute>} path="/manage-landrecords"/>
    //     <Route element= {<ProtectedRoute><AddFeildInformation/></ProtectedRoute>} path="/add-feild-information"/>
    //     <Route element= {<ProtectedRoute><AddFeildInformation/></ProtectedRoute>} path="/update-feild-information/:id"/>
    //     <Route element= {<ProtectedRoute><ManageFeildInformation/></ProtectedRoute>} path="/manage-feild-information"/>
    //     <Route element= {<ProtectedRoute><AddAeration/></ProtectedRoute>} path="/add-aeration"/>
    //     <Route element= {<ProtectedRoute><ManageAerationDetails/></ProtectedRoute>} path="/manage-aeration"/>
    //     <Route element= {<ProtectedRoute><ManageMembers/></ProtectedRoute>} path="/manage-members"/>
    //     <Route element= {<ProtectedRoute><Profile/></ProtectedRoute>} path="/farmer-profile/:id"/>
    //     <Route element= {<ProtectedRoute><AdminProfile /></ProtectedRoute>} path="/admin-profile" />
    //     <Route element= {<ProtectedRoute><AddPolygonFile /></ProtectedRoute>} path="/polygon-mapping" />
    //     <Route element= {<ProtectedRoute><ManagePolygon /></ProtectedRoute>} path="/manage-polygon-mapping" />
    //     <Route element= {<ProtectedRoute><DownloadKMLs /></ProtectedRoute>} path="/export-polygons" />
    //     <Route element= {<ProtectedRoute><TrackUsers /></ProtectedRoute>} path="/track" />
    //     <Route element= {<ProtectedRoute><Inputs /></ProtectedRoute>} path="/approvals" />
    //     <Route element = {<ProtectedRoute><ManageLocations/></ProtectedRoute>} path="/manage-location"/>
    //     <Route element = {<ProtectedRoute><Documents /></ProtectedRoute>} path="/documents"/>
    //     <Route element = {<ProtectedRoute><ManageDocument/></ProtectedRoute>} path="/manage-documents"/>
    //     {/* <Route element= {<ErrorPage/>} path="*" /> */}
        
    //     </Routes>
     
    //   <NotificationContainer/>
    // </HashRouter> 