import { useEffect } from "react";
import Filter from "./Filter";
import Layout from "../../Layout";

const Documents = () => {
    
    return (
        <div className="col-12 grid-margin">
            <div className="card">
                <div className="card-body">
                    <Filter />
                </div>
            </div>
        </div> 
    );
}

export default Documents;