
import AerationTable from "./AerationTable/AerationTable";
import Layout from "../../../Layout";

const ManageAerationDetails = () => {
    return (
        <div className="col-12 grid-margin">
            <div className="card">
                <div className="card-body">
                    <h4>Aeration Table</h4>
                    <AerationTable/>
                </div>
            </div>
        </div>
    );
}

export default ManageAerationDetails;