import { projectList, userRoles } from "./values";


const projectList_ = projectList.map(project => project.value);
console.log(projectList_)

const SIDEBAR = [
	{
		name : 'Dashboard',
		id : 'Dashboard',
		projects : projectList_,
		icon: 'typcn-chart-area',
		roles : [userRoles.admin, userRoles.superadmin, userRoles.editor, userRoles.intern, userRoles.viewer],
		route : '/dashboard'
	},
	{
		name : 'Onboarding',
		id : 'onboarding',
		projects : projectList_,
		icon : 'typcn-th-list-outline',
		roles : [userRoles.admin, userRoles.superadmin, userRoles.editor, userRoles.viewer],
		children : [
			{
				name : 'Add',
				id : 'add',
				route : '/add-onboarding',
				roles : [userRoles.admin, userRoles.superadmin, userRoles.editor]
			},
			{
				name : 'Manage',
				id : 'manage',
				route : '/manage-onboarding',
				roles : [userRoles.admin, userRoles.superadmin, userRoles.editor, userRoles.viewer]
			}
		]
	},
	{
		name : 'Documents',
		id : 'documents',
		projects : projectList_,
		icon : 'typcn-document-text',
		roles : [userRoles.admin, userRoles.superadmin, userRoles.editor, userRoles.viewer],
		children : [
			{
				name : 'Add',
				id : 'add',
				route : '/documents',
				roles : [userRoles.admin, userRoles.superadmin, userRoles.editor]
			},
			{
				name : 'Manage',
				id : 'manage',
				route : '/manage-documents',
				roles : [userRoles.admin, userRoles.superadmin, userRoles.editor, userRoles.viewer]
			}
		]
	},
	{
		name : 'Polygon Mapping',
		id : 'polygons',
		projects : projectList_,
		icon : 'typcn-map',
		roles : [userRoles.admin, userRoles.superadmin, userRoles.editor, userRoles.viewer],
		children : [
			{
				name : 'Add',
				id : 'add',
				route : '/polygon-mapping',
				roles : [userRoles.admin, userRoles.superadmin, userRoles.editor]
			},
			{
				name : 'Manage',
				id : 'manage',
				route : '/manage-polygon-mapping',
				roles : [userRoles.admin, userRoles.superadmin, userRoles.editor, userRoles.viewer]
			}
		]
	},
	{
		name : 'Crops Information',
		id : 'crops',
		projects : projectList_,
		icon : 'typcn-film',
		roles : [userRoles.admin, userRoles.superadmin, userRoles.editor, userRoles.viewer],
		children : [
			{
				name : 'Add',
				id : 'add',
				route : '/add-feild-information',
				roles : [userRoles.admin, userRoles.superadmin, userRoles.editor]
			},
			{
				name : 'Manage',
				id : 'manage',
				route : '/manage-feild-information',
				roles : [userRoles.admin, userRoles.superadmin, userRoles.editor, userRoles.viewer]
			}
		]
	},
	{
		name : 'Land Records',
		id : 'landRecords',
		icon : 'typcn-document-text',
		projects : [projectList_[0]],
		roles : [userRoles.admin, userRoles.superadmin, userRoles.editor, userRoles.intern, userRoles.viewer],
		children : [
			{
				name : 'Add',
				id : 'add',
				route : '/add-landrecords',
				roles : [userRoles.admin, userRoles.superadmin, userRoles.editor, userRoles.intern]
			},
			{
				name : 'Manage',
				id : 'manage',
				route : '/manage-landrecords',
				roles : [userRoles.admin, userRoles.superadmin, userRoles.editor, userRoles.intern, userRoles.viewer]
			}
		]
	},
	{
		name : 'Aeration',
		id : 'aeration',
		projects : [projectList_[0]],
		icon : 'typcn-chart-pie-outline',
		roles : [userRoles.admin, userRoles.superadmin, userRoles.editor, userRoles.intern, userRoles.viewer],
		children : [
			{
				name : 'Add',
				id : 'add',
				route : '/add-aeration',
				roles : [userRoles.admin, userRoles.superadmin, userRoles.editor, userRoles.intern]
			},
			{
				name : 'Manage',
				id : 'manage',
				route : '/manage-aeration',
				roles : [userRoles.admin, userRoles.superadmin, userRoles.editor, userRoles.intern, userRoles.viewer]
			}
		]
	},
	{
		name : 'Users',
		id : 'users',
		projects : projectList_,
		roles : [userRoles.superadmin],
		icon : 'typcn-user-add-outline',
		route : '/manage-members'
	},
	{
		name : 'Track Users',
		id : 'track-users',
		projects : projectList_,
		roles : [userRoles.superadmin],
		icon : 'typcn-group',
		route : '/track',
	},
	{
		name : 'Approvals',
		id : 'approvals',
		projects : projectList_,
		roles : [userRoles.superadmin],
		icon : 'typcn-input-checked',
		route : '/approvals',
	},
	{
		name : 'Locations',
		id : 'locations',
		projects : projectList_,
		roles : [userRoles.superadmin, userRoles.admin],
		icon : 'typcn-location',
		route : '/manage-location',
	},
];

export default SIDEBAR;