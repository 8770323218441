import { useEffect, useState } from "react";
import { addAerationDetails, deleteAerationDetailByPipe, getAerationDetailsByFarmerId, updateAerationDetails, updateAerationDetails1 } from "../../../APIs/apiCalls";
import Form_ from "./AerationForm/Form";
import FeildInfo from "./AerationForm/FeildInfo";
import { NotificationManager } from "react-notifications";
import AlertConfirm from "react-alert-confirm";
import DryForm from "./AerationForm/DryForm";
import DryFeildInfo from "./AerationForm/DryFeildInfo";
import Layout from "../../../Layout";
import { getPosition } from "../../../Utils/commonFunctions";
import LocationDeniedError from "../../../Components/LocationError/LocationDeniedError";
import FarmerSearchBar from "../../../Components/FarmerSearchBar/FarmerSearchBar";
import { useNavigate } from "react-router-dom";

const AddAeration = () => {
   
    const [object, setObject] = useState(undefined);
    const [activity, setActivity] = useState('');
    const [lat, setLat] = useState(0);
    const [lng, setLng] = useState(0);
    const [editingState, setEditingState] = useState(false);
    const [locationAllowed, setLocationAllowed] = useState(undefined);
    const [editIndexNumber, setEditIndexNumber] = useState(-1);
    const [activityEditState, setActivityEditState] = useState(true);
    const [accuracy, setAccuracy] = useState(0);
    const [finishStatus, setFinishStatus] = useState(false);
    const [flag, setFlag] = useState(false);
    const [farmerId, setFarmerId] = useState('');
    const [comments, setComments] = useState('');
    const navigate = useNavigate();

    const pipes = Array.from({ length: 40 }, (_, index) => index + 1);

    const alertUser = (event) => {
        event.preventDefault();
        event.returnValue = "Are you sure you want to leave? Changes you made may not be saved."; // Custom message may not work in all browsers
    };

    const onBackButtonEvent = (event) => {
        event.preventDefault();
        if (!finishStatus) {
            if (window.confirm("Do you want to go back to the dashboard?")) {
                setFinishStatus(true); // Confirm navigation
                navigate("/dashboard");
            } else {
                window.history.pushState(null, null, window.location.hash);
                setFinishStatus(false); // Stay on the page
            }
        }
    };

     

    useEffect(() => {
        window.history.pushState(null, null, window.location.hash);
        window.addEventListener('popstate', onBackButtonEvent);
        window.addEventListener('beforeunload', alertUser);

        return () => {
            window.removeEventListener('popstate', onBackButtonEvent);
            window.removeEventListener('beforeunload', alertUser);
        };
    }, [finishStatus]);



    const saveDetails = (farmer) => {
        const farmerId = farmer._id;
        setFarmerId(farmer._id);
        getAerationDetailsByFarmerId(farmerId).then((response) => {
            console.log(response.data[0]);
            setObject(response.data[0]);
            setFlag(true);
        })
    }

    //invokes when number of pipes changed
    const changeHandler = (event) => {
        let pipes = parseInt(event.target.value);

        console.log(object);
        if(object === undefined){
            //it means they are adding for the first time.
            AlertConfirm({
                title: `You have selected, Number of Pipes : ${pipes}`,
                desc: "Shall we move forward?",
                onOk: () => {
                    let emptyArraysForPipe = Array(pipes).fill({ date_ : '', file : '',type : '', open : '', file2 : '',type2 : '', open2 : '' });
                    let emptyArraysForDry = Array(pipes).fill({ date_ : '', file1 : '',type1 : '', open1 : '', file2 : '',type2 : '', open2 : '' });
                    let newEntry = {
                        farmerId : farmerId,
                        pipesRequired : pipes,
                        pipeInstallments :  emptyArraysForPipe,
                        firstDry : emptyArraysForDry,
                        secondDry : emptyArraysForDry,
                        comments :  `${pipes} pipes are selected.`
                    }
                    addAerationDetails(newEntry).then((response) => {
                        setObject(response.data)
                    })
                },
                onCancel: () => {
                  // console.log('cancel');
                }
            });
        }else{
            //it means they are changing the pipe..
            let {pipeInstallments, firstDry, secondDry } = object;
            let previousPipeValue = object.pipesRequired;
            
            console.log(pipeInstallments);

            let index = pipeInstallments.findLastIndex((pipe) => pipe.date_ != "");
            console.log(index);
            if(pipes < (index+1)){
                NotificationManager.error("Number of pipes can't be reduced. Pipe Installation activity has already saved records.");
                return;
            }

            index = firstDry.findLastIndex((pipe) => pipe.date_ != "");
            if(pipes < (index+1)){
                NotificationManager.error("Number of pipes can't be reduced. First Dry activity has already saved records.");
                return;
            }

            index = secondDry.findLastIndex((pipe) => pipe.date_ != "");
            if(pipes < (index+1)){
                NotificationManager.error("Number of pipes can't be reduced. Second Dry activity has already saved records.")
                return;
            }

            AlertConfirm({
                title: `You have selected, Number of Pipes : ${pipes}`,
                desc: "Shall we move forward?",
                onOk: () => {
                    let n = pipeInstallments.length;

                    if(pipes<n){

                        pipeInstallments = pipeInstallments.slice(0, pipes);
                        firstDry = firstDry.slice(0, pipes);
                        secondDry = secondDry.slice(0, pipes);
                        
                    }else if(pipes>n){
                        
                        let emptyArraysForPipe = Array(pipes - n).fill({ date_ : '', file : '',type : '', open : '',  file2 : '',type2 : '', open2 : '' });
                        let emptyArraysForDry = Array(pipes - n).fill({ date_ : '', file1 : '',type1 : '', open1 : '', file2 : '',type2 : '', open2 : '' });
                        
                        pipeInstallments = pipeInstallments.concat(emptyArraysForPipe);
                        firstDry = firstDry.concat(emptyArraysForDry);
                        secondDry = secondDry.concat(emptyArraysForDry);
                    }
                    let newEntry = {pipeInstallments, firstDry, secondDry, farmerId : object.farmerId, pipesRequired : pipes, 
                        comments : `Pipe Requirement is changed from ${previousPipeValue} to ${pipes}`};
                    // console.log(newEntry);
                    updateAerationDetails(object._id, newEntry).then((response) => {
                        console.log(response);
                        if(response.result === 'success'){
                            setObject(response.data);
                            setEditingState(false);
                            NotificationManager.success('You have changed the number of pipes.');
                        }
                    }).catch((error) => {
                        NotificationManager.success('Something went wrong');
                    })
                },
                onCancel: () => {
                    setEditingState(false);
                }
            });
            
        }
    }
    const customizeValue = (value, activity) => {
        let updatedValue = {date_ : value.date_};
        switch(activity){
            case 'pipe' : 
            updatedValue['type'] = value.type;
            updatedValue['file'] = value.type === 'camera' || (value.file.includes('https://')=== true) ? value.file : '';
            updatedValue['type2'] = value.type2;
            updatedValue['file2'] = value.type2 === 'camera' || (value.file2.includes('https://')=== true) ? value.file2 : '' ;
            break;
            default : 
            updatedValue['type1'] = value.type1;
            updatedValue['file1'] = value.type1 === 'camera'  || (value.file1.includes('https://')=== true) ? value.file1 : '' ;
            updatedValue['type2'] = value.type2;
            updatedValue['file2'] = value.type2 === 'camera' || (value.file2.includes('https://')=== true) ? value.file2 : '' ;
            break;
        }
        return updatedValue;
    }
    const submitHandler = (value, index) => {
        const {fileObject, fileObject1, fileObject2} = value;
        let updatedEntry = {};
        value = customizeValue(value, activity);
        updatedEntry['lat'] = lat;
        updatedEntry['lng'] = lng;
        updatedEntry['accuracy'] = lng;
        updatedEntry['farmerId'] = object.farmerId;
        updatedEntry['activity'] = activity;
        updatedEntry['index'] = index;
        // console.log(updatedEntry);
        if(activity === 'dry1'){
            let arr = object.firstDry;
            arr[index] = value;
            updatedEntry['firstDry'] = arr;
            updatedEntry['comments'] = `Worked on First Dry Details for pipe number ${index+1}.`;
        }
        if(activity === 'dry2'){
            let arr = object.secondDry;
            arr[index] = value;
            updatedEntry['secondDry'] = arr;
            updatedEntry['comments'] = `Worked on Second Dry Details for pipe number ${index+1}.`;
        }
        if(activity === 'pipe'){
            let arr = object.pipeInstallments;
            arr[index] = value;
            // console.log(arr);
            updatedEntry['pipeInstallments'] = arr;
            updatedEntry['comments'] = `Worked on Pipe Installation Details for pipe number ${index+1}.`;
        }
        let formData = new FormData();
        formData.append('fileObject', fileObject);
        formData.append('fileObject1', fileObject1);
        formData.append('fileObject2', fileObject2);
        formData.append('value', JSON.stringify(updatedEntry));
        updateAerationDetails1(object._id, formData).then((response) => {
            // console.log(response);
            let {activity} = object;
            setObject({...response.data, activity});
            if(response.result === 'success'){
                NotificationManager.success('Successfully saved the aeration details.');
            
                //set to initial state
                onEditCompleted();
                
            }else{
                NotificationManager.error('Something went wrong.');
            }
        }).catch((error) => {
            NotificationManager.error('Something went wrong.');

            //set to initial state
            onEditCompleted();
        })
    }

    const pipeChangeHandler = () => {
        setEditingState(true);
    }

    const onEditCompleted = () => {
        setActivityEditState(false);
        setEditIndexNumber(-1);
    }

    const activityChangeHandler = (index, operation) => {

        switch(operation){
            case 'edit' : 
            setEditIndexNumber(index);
            setActivityEditState(true);
            break;
            case 'delete' : 

            AlertConfirm({
                title: `This cannot be undone.`,
                desc: "Shall we move forward?",
                onOk: () => {
                    let obj = object;

                    if(activity === 'pipe'){
                        obj.pipeInstallments[index] = { date_ : '', file : '',type : '', open : '', file2 : '',type2 : '', open2 : ''};
                        obj['comments'] = `Pipe Installation Details are removed for pipe number ${index+1}`;
                    }
                    else if(activity === 'dry1'){
                        obj.firstDry[index] = { date_ : '', file1 : '',type1 : '', open1 : '', file2 : '',type2 : '', open2 : '' };
                        obj['comments'] = `First Dry Details are removed for pipe number ${index+1}`;
                    }else{
                        obj.secondDry[index] = { date_ : '', file1 : '',type1 : '', open1 : '', file2 : '',type2 : '', open2 : '' };
                        obj['comments'] = `Second Dry Details are removed for pipe number ${index+1}`;
                    }
        
                    deleteAerationDetailByPipe(object._id, obj).then((response) => {
                        if(response.result === 'success'){
                            setObject(response.data);
                            NotificationManager.success('Successfully removed the entry.');
                        }
                    }).catch((error) => {
                        NotificationManager.error('Something went wrong.');
                    })
                },
                onCancel : () => {},
            });
            break;
        }
    }

    useEffect(()=> {
        const fetchLocation = async () => {
            try {
              const position = await getPosition();
              const { latitude, longitude, accuracy } = position.coords;
              
              // Assuming setLat and setLng are useState setters from your component
              setLocationAllowed(true);
              setLat(latitude);
              setLng(longitude);
              setAccuracy(accuracy);
            } catch (error) {
              setLocationAllowed(false);
              NotificationManager.error(error.message, 'Error');
            }
          };
      
          fetchLocation();
    },[])

    return (
        <>
            { locationAllowed === false && <LocationDeniedError/>}
            {
                locationAllowed === true &&
                <div className="col-12 grid-margin">
                    <div className="card">
                        <div className="card-body">
                            {/* <Filter generate={saveDetails}/> */}
                            {
                                lat === 0 ? <span>Fetching Locaton</span> : <FarmerSearchBar change={saveDetails}/>
                            }
                        </div>
                    </div>
                
                        
                        {
                            flag === true ? 
                            <div className="card mt-2">
                                <div className="card-body">
                                    {
                                        object === undefined || editingState === true ? 
                                        <>
                                        <div className="col-md-4">
                                            <select className="form-control" onChange={(event) => changeHandler(event)} value={object != undefined ? object.pipesRequired : ''}>
                                                <option value='' disabled={true}>Select Pipes</option>
                                                {
                                                    pipes.map((pipe) => <option value={pipe}>{pipe}</option>)
                                                }
                                            </select>
                                        </div> 
                                        </>: <div  className="col-md-4 mt-2">Selected Number of Pipes : {object.pipesRequired} &nbsp; <button className="btn btn-success p-2" onClick={() => pipeChangeHandler()}><i className="typcn typcn-edit"></i></button></div>
                                    }
                            
                                    <div className="col-md-4 mt-2">       
                                        <select className="form-control" onChange={(event) => setActivity(event.target.value)}>
                                            <option value=''>Select Activity</option>
                                            <option value='pipe'>Pipe Installation</option>
                                            <option value='dry1'>First Dry</option>
                                            <option value='dry2'>Second Dry</option>
                                        </select>
                                    </div>
                                </div>
                            </div> : null
                        }

                        {
                            activity!='' &&
                            <div className="card mt-2">
                                <div className="card-body p-0">
                                    
                                    { 
                                        activity === 'pipe' ? 
                                        <> { object.pipeInstallments.map((item, index) =>  <> {item.date_ === '' || (activityEditState === true && editIndexNumber === index) ? <Form_ key={index} value={item} index={index} submit={submitHandler}/> : <FeildInfo key={index} value={item} index = {index} farmername={object.farmername} change={(operation) => activityChangeHandler(index, operation)}/>} </>)}</>  : '' 
                                    }
                                    { 
                                        activity === 'dry1' ? 
                                        <> { object.firstDry.map((item, index) =>  <> {item.date_ === '' || (activityEditState === true && editIndexNumber === index) ? <DryForm key={index} value={item} operation='First Dry' index={index} submit={submitHandler}/> : <DryFeildInfo key={index} value={item} operation='First Dry' index = {index} farmername={object.farmername} change={(operation) => activityChangeHandler(index, operation)}/>} </>)}</>  : '' 
                                    }
                                    { 
                                        activity === 'dry2' ? 
                                        <> { object.secondDry.map((item, index) =>  <> {item.date_ === '' || (activityEditState === true && editIndexNumber === index) ? <DryForm key={index} value={item} operation='Second Dry' index={index} submit={submitHandler}/> : <DryFeildInfo key={index} value={item} index = {index} operation='Second Dry' farmername={object.farmername} change={(operation) => activityChangeHandler(index, operation)}/>} </>)}</>  : '' 
                                    }
                                </div>
                            </div>
                        }
                </div>
            }
                    
        </>
    );
}

export default AddAeration;