import { useEffect, useState } from "react";
import {  deletePendingRecords, getPendingRecords, updatePendingRecords } from "../../APIs/apiCalls";
import Table from "./Table";
import { NotificationManager } from "react-notifications";
import PestAndDiseasesTable from "./PestsAndDiseasesTable";
import Layout from "../../Layout";

const Inputs = () => {
    
    const [fertilizers, setFertilizers] = useState([]);
    const [arr1, setArr1] = useState([]);
    const [arr2, setArr2] = useState([]);
    const [arr3, setArr3] = useState([]);
    const [arr4, setArr4] = useState([]);
    const [arr5, setArr5] = useState([]);
    const [arr6, setArr6] = useState([]);
    const [arr7, setArr7] = useState([]);
    const [flag, setFlag] = useState(false);
// calc(70px + 0.5rem);
// margin-left: calc(227px + 0.5rem);
    
    const deleteHandler = (id) => {
        deletePendingRecords(id).then((response) => {
            // console.log(response);
            NotificationManager.success("Option Discarded successfully");
            setFlag(!flag)
        })
    }
    const handleChange = (id, values, collection) => {
        // console.log(id);
        // console.log(values);
        updatePendingRecords(id, values, collection ).then((response)=>{
            // console.log(response);
            NotificationManager.success("Option Added in the Dropdown");
            setFlag(!flag);
        })
    }

    useEffect(()=>{
        (async ()=>{
            const dropdowns = (await getPendingRecords()).data;
            // console.log(dropdowns);
            const fertilizers = dropdowns.filter((item) => item.collection_ === 'fertilizers');
            setFertilizers(fertilizers);
            let bactericides = dropdowns.filter((item) => item.collection_ === 'fungicides');
            setArr1(bactericides);
            let irrigations = dropdowns.filter((item) => item.collection_ === 'irrigations');
            setArr2(irrigations)
            let organicFertlizers = dropdowns.filter((item) => item.collection_ === 'organic-fertilizers');
            setArr3(organicFertlizers)
            let pesticides = dropdowns.filter((item) => item.collection_ === 'pesticides');
            setArr4(pesticides);
            let arr5 = dropdowns.filter(item => item.type === 'pest');
            // console.log(arr5);
            setArr5(arr5);
            arr5 = dropdowns.filter(item => item.type === 'crop');
            setArr6(arr5);
            arr5 = dropdowns.filter(item => item.type === 'diseases');
            setArr7(arr5);
        })();

    },[flag]);
    return (
        <div className="col-12 grid-margin">
            <div className="card" id="fertilizers">
                <div className="card-body">
                    <Table data={fertilizers} delete_={deleteHandler} change={handleChange} heading='Fertilizers' collection='fertilizers'/>
                </div>
            </div>
            <div className="card mt-3" >
                <div className="card-body" id="pesticides">
                    <Table data={arr4} delete_={deleteHandler} change={handleChange} heading='Pesticides' collection='pesticides'/>
                </div>
            </div>
            <div className="card mt-3" id="fungicides">
                <div className="card-body">
                    <Table data={arr1} delete_={deleteHandler} change={handleChange} heading='Bactericides/Fungicides' collection='fungicides'/>
                </div>
            </div>
            <div className="card mt-3" id="organic-fertilizers">
                <div className="card-body">
                    <Table data={arr3} delete_={deleteHandler} change={handleChange} heading='Organic Fertilizers' collection='organic-fertilizers'/>
                </div>
            </div>
            <div className="card mt-3" id="irrigation">
                <div className="card-body">
                    <Table data={arr2} delete_={deleteHandler} change={handleChange} heading='Irrigation' collection='irrigations'/>
                </div>
            </div>
            <div className="card mt-3" id="pests">
                <div className="card-body">
                    <PestAndDiseasesTable data={arr5} delete_={deleteHandler} change={handleChange} heading='Pests' collection='pests-and-diseases'/>
                </div>
            </div>
            <div className="card mt-3" id="crops">
                <div className="card-body">
                    <Table data={arr6} delete_={deleteHandler} change={handleChange} heading='Crops' collection='pests-and-diseases'/>
                </div>
            </div>
            <div className="card mt-3" id="diseases">
                <div className="card-body">
                    <PestAndDiseasesTable data={arr7} delete_={deleteHandler} change={handleChange} heading='Diseases' collection='pests-and-diseases'/>
                </div>
            </div>
        </div>
    );
}

export default Inputs;