import { useEffect, useState } from "react";
import { adjustWidth } from "./Utils/commonFunctions";
import Header from "./Components/Header/Header";
import { Outlet, useNavigate } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import { isTokenValid } from "./APIs/apiCalls";
import { Sidebar } from "./Components/Sidebar/Sidebar_";

const Layout = () => {
    const navigate = useNavigate();
    const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth <= 991 ? false : true);
    const [user, setUser] = useState({});
    
    const clickHandler = () => {
        const flag = adjustWidth(isSidebarOpen);
        setIsSidebarOpen(flag);
    }
    
    useEffect(() => {
        (async ()=>{
            try{
                const token = localStorage.getItem('token');
          
                const response = await isTokenValid({token});
                console.log(response);
            
                setUser(response.data);
            }catch(error){
                NotificationManager.error("Session Expired")
                navigate('/signin')
            }
          
        })();
      },[isSidebarOpen]);

    return (
        <div className="container-scroller">
            <div className="container-fluid page-body-wrapper">
                <Header clickHandler={clickHandler} />
                <Sidebar isSidebarOpen = {isSidebarOpen} user = {user}/>
                <div className="main-panel">
                    <div className="content-wrapper">
                        <div className="row" style={{paddingTop : window.innerWidth <= 991 ? '75px' : '77.5px'}}>
                            <Outlet />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Layout;