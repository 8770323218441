import { Link, useLocation } from "react-router-dom";
import SIDEBAR from "../../ini/sidebar";

export const Sidebar = ({user, isSidebarOpen}) => {
	const {pathname} = useLocation();
	console.log(user)
	if (user === null) {
		return <div>Loading...</div>;
	}
	return (
		<nav className={`sidebar sidebar-offcanvas ${isSidebarOpen==true? 'active' : ''} bg-green`} id="sidebar"  >
        	<ul className="nav"> 
				{
					SIDEBAR.map((item, index) => 
						item.children === undefined ? 
						<>
						{
							item.roles.includes(user.type) === true && ( user.projects.length === 0 || user.projects.some((project) => item.projects.includes(project)===true)) ? 
							<li key={index} className={`nav-item ${pathname === item.route ? 'active' : ''}`}>
								<Link className="nav-link" to={item.route}>
									<i className={`typcn ${item.icon} menu-icon`}></i>
									<span className="menu-title">{item.name}</span>
								</Link>
							</li> : null
						}
						</> : 
						<>
						{
							item.roles.includes(user.type) === true && ( user.projects.length === 0 || user.projects.some((project) => item.projects.includes(project)===true)) ? 
							<li className={`nav-item ${item.children.map((child) => child.route).includes(pathname) === true ? 'active' : ''}`}>
								<a className="nav-link " data-toggle="collapse" href={`#${item.id}`} aria-expanded="false" aria-controls={item.id}>
									<i className={`typcn ${item.icon} menu-icon`}></i>
									<span className="menu-title">{item.name}</span>
									<i className="menu-arrow"></i>
								</a>
								<div className="collapse" id={item.id}>
									<ul className="nav flex-column sub-menu">
									{
										item.children.map((child, key) => 
										<>
											{
												child.roles.includes(user.type) === true ? <li key={key} className="nav-item"> <Link className="nav-link " to={child.route}>{child.name} </Link></li>  : null
											}
										</>
										)
									}
									</ul>
								</div>
							</li> : null
						}
						
						</>
					)
				}
			</ul>
		</nav>
	);

}