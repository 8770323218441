import Layout from "../../../Layout";
import FeildInformation from "./FeildInformation/FeildInformation";

const ManageFeildInformation = () => {
    
    return (
        
        <div className="col-12 grid-margin">
            <div className="card">
                <div className="card-body1">
                    <h4>Crop Details</h4>
                    <FeildInformation />
                </div>
            </div>
        </div>
    );
}

export default ManageFeildInformation;