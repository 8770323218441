import React, { useEffect, useState } from 'react'
import Select from 'react-select';
import styles from './LandRecordForm.module.css';
import makeAnimated from 'react-select/animated';
import { useFormik } from 'formik';
import ESignature from '../../../Documents/Agreement/ESignature';
import { format } from 'date-fns';
import { addLeasedAgreement } from '../../../../APIs/apiCalls';
import { NotificationManager } from 'react-notifications';

const getFullYear = (new Date()).getFullYear();
const formattedDate = (new Date()).toISOString().split('T')[0];


const PreviewSignature = ({uri}) => {
    return <img height={90} width={170} src={uri}/>
}

const validate = (values) => {
    const error = {};
    if(values.fromYear===''){
        error.fromYear = 'Year is required';
    }

    if(values.khasraNumbers.length ===0){
        error.khasraNumbers = 'Khasra Number is required';
    }

    
    if(values.ownerName===''){
        error.ownerName = 'Name is required';
    }

    if(values.ownerDistrict===''){
        error.ownerDistrict = 'District is required';
    }
    if(values.ownerGuardian===''){
        error.ownerGuardian = 'Guardian is required';
    }
    if(values.ownerVillage===''){
        error.ownerVillage = 'Village is required';
    }
    if(values.ownerBlock===''){
        error.ownerBlock = 'Block is required';
    }
    if(values.ownerRelation===''){
        error.ownerRelation = 'Relation is required';
    }
    if(values.area===''){
        error.area = 'Area is required';
    }

    // if(values.ownerSignType===''){
    //     error.ownerSignType = 'Sign Type is required';
    // }
    // if(values.farmerSignType===''){
    //     error.farmerSignType = 'SignType is required';
    // }
    if(values.ownerDate===''){
        error.ownerDate = 'Owner Date is required';
    }
    if(values.farmerDate===''){
        error.farmerDate = 'Farmer Date is required';
    }
    if(values.farmerRelation===''){
        error.farmerRelation = 'Relation is required';
    }
    console.log(error);
    return error;
}

const LeaseAgreementSign = ({farmer, location, changeRecords, remove}) => {
    console.log(farmer);
    const animatedComponents = makeAnimated();
    const [language, setLanguage] = useState('hindi');
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [disabled, setDisabled] = useState(false);
    console.log(farmer._id);
    console.log('====================================');
    console.log(farmer);
    console.log('====================================');
    const transformedArray = farmer.leasedLandKhasraNumbers ? farmer.leasedLandKhasraNumbers?.map(item => {
        return { label: item, value: item };
    }) : [];
    
    
    const formik = useFormik({
        initialValues : {
            fromYear : getFullYear,
            toYear : getFullYear,
            khasraNumbers : [],
            area : '',
            farmerId : farmer._id,
            ownerName : '',
            ownerGuardian : '',
            ownerVillage : '',
            ownerBlock : '',
            ownerDistrict : '',
            ownerSign : '',
            ownerSignType : '',
            ownerDate  : '',
            ownerRelation  : '',
            farmerName : '',
            farmerGuardian : '',
            farmerVillage : '',
            farmerBlock : '',
            farmerDistrict : '',
            farmerSign : '',
            farmerSignType : '',
            farmerDate  : '',
            farmerRelation  : '',
        },
        validate,
        onSubmit : (values) => {
            console.log(values);
            setDisabled(true);
            addLeasedAgreement(values).then(response => {
                console.log(response);
                setDisabled(false);
                NotificationManager.success('Record saved successfully')
                changeRecords();
            }).catch(error => {
                setDisabled(false);
                NotificationManager.error('Internal Server Error')
                console.log(error);
            })
        }
    });


    const selectHandler = (event) => {
        const values = event.map((value) =>{ return value.value});
        formik.setFieldValue('khasraNumbers',values)
    }

    useEffect(() => {
        const {farmername,guardian, village, block, district} = farmer;
        formik.setFieldValue('farmerName', farmername);
        formik.setFieldValue('farmerGuardian', guardian);
        formik.setFieldValue('farmerVillage', village);
        formik.setFieldValue('farmerBlock', block);
        formik.setFieldValue('farmerDistrict', district)
    },[farmer])
    
  return (
    <div>
        <h5>Leased Agreement</h5>
        <div className='row'>
            <div className='col-md-3 form-group'>
                <select className='form-control' value={language} onChange={(event) => setLanguage(event.target.value)}>
                    <option>Select language</option>
                    <option value='english'>English</option>
                    <option value='hindi'>Hindi</option>
                </select>
            </div>
        </div>
                <div>
                    <form onSubmit={formik.handleSubmit}>
                        <p>{language === 'english' ? 'This is to certify that I' : 'यह प्रमाणित किया जाता है कि मैं'}, 
                            <div style={{display : 'inline'}}>
                                <input className={`col-md-3 form-control  ${styles.lineInput} ${ formik.touched.ownerName === true && formik.errors.ownerName ? styles.formInputError : ''}`}  name='ownerName' value={formik.values.ownerName} onChange={formik.handleChange} onBlur={formik.handleBlur} placeholder='owner name'/>
                            </div>,  
                            <select className={`col-md-3 form-control  ${styles.lineInput} ${ formik.touched.ownerRelation === true && formik.errors.ownerRelation ? styles.formInputError : ''}`}  name='ownerRelation' value={formik.values.ownerRelation} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                                <option value=''>Select Relation</option>
                                <option value='son'>{language === 'english' ? 'Son' : 'पुत्र' }</option>
                                <option value='daughter'>{language === 'english' ? 'Daughter' : 'पुत्री' }</option>
                                <option value='wife'>{language === 'english' ? 'Wife' : 'पत्नी'}</option>
                            </select>
                            <input placeholder='owner guardian' className={`col-md-3 form-control ${styles.lineInput} ${formik.touched.ownerGuardian && formik.errors.ownerGuardian ? styles.formInputError : ''}`} name='ownerGuardian' value={formik.values.ownerGuardian} onChange={formik.handleChange} onBlur={formik.handleBlur}/>, 
                            {language === 'english' ? 'district' : 'जिला'} 
                             <select
                            className={`col-md-3 form-control ${styles.lineInput} ${formik.touched.ownerDistrict  && formik.errors.ownerDistrict ? styles.formInputError : ''}`} 
                            name='ownerDistrict' 
                            value={formik.values.ownerDistrict} 
                            onChange={formik.handleChange} onBlur={formik.handleBlur}
                            >
                                <option>Select District</option>
                                {
                                    Array.from(new Set(location.map(item => item.district))).sort().map((item,index) => <option value={item} key={index}>{item.toUpperCase()}</option>)
                                }
                            </select>
                            
                            , 
                            {language === 'english' ? 'block' : 'ब्लॉक'}  -
                            <select className={`col-md-3 form-control ${styles.lineInput} ${formik.touched.ownerBlock && formik.errors.ownerBlock ? styles.formInputError : ''}`} 
                            name='ownerBlock' value={formik.values.ownerBlock}
                            onBlur={formik.handleBlur} onChange={formik.handleChange} >
                                <option>Select Block</option>
                                {
                                    Array.from(new Set(location.filter(item => item.district === formik.values.ownerDistrict).map(item => item.block))).sort().map((item,index) => <option value={item} key={index}>{item.toUpperCase()}</option>)
                                }
                            </select>, 
                            {language === 'english' ? 'village' : 'गाँव'} 
                            <select className={`col-md-3 form-control ${styles.lineInput} ${formik.touched.ownerVillage && formik.errors.ownerVillage ? styles.formInputError : ''}`} 
                            name='ownerVillage' value={formik.values.ownerVillage}
                            onBlur={formik.handleBlur} onChange={formik.handleChange} >
                                <option>Select Village</option>
                                {
                                    Array.from(new Set(location.filter(item => item.block === formik.values.ownerBlock).map(item => item.village))).sort().map((item,index) => <option value={item} key={index}>{item.toUpperCase()}</option>)
                                }
                            </select>, 
                            {language === 'english' ? 'is allowing' : `को अनुमति दे ${formik.values.ownerRelation === 'son' ? 'रहा' : formik.values.ownerRelation === '' ? 'रहा/रही' : 'रही'} हूँ कि निवासी`}  <input className={`col-md-3 form-control ${styles.lineInput}`} name='farmerName' value={formik.values.farmerName}
                            disabled={true}/>, 
                            <select className={`col-md-3 form-control  ${styles.lineInput} ${ formik.touched.farmerRelation === true && formik.errors.farmerRelation ? styles.formInputError : ''}`}  name='farmerRelation' value={formik.values.farmerRelation} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                                <option value=''>Select Relation</option>
                                <option value='son'>{language === 'english' ? 'Son' : 'पुत्र' }</option>
                                <option value='daughter'>{language === 'english' ? 'Daughter' : 'पुत्री' }</option>
                                <option value='wife'>{language === 'english' ? 'Wife' : 'पत्नी'}</option>
                            </select>  
                            <input className={`col-md-3 form-control ${styles.lineInput}`} name='farmerGuardian' value={formik.values.farmerGuardian} disabled={true}/>, 
                            {language === 'english' ? 'village' : 'गाँव'} <input className={`col-md-3 form-control ${styles.lineInput}`} name='farmerVillage' value={formik.values.farmerVillage} disabled={true}/> ,
                            {language === 'english' ? 'block' : 'ब्लॉक'}  <input className={`col-md-3 form-control ${styles.lineInput}`} name='farmerBlock' value={formik.values.farmerBlock} disabled={true}/>, 
                            {language === 'english' ? 'district' : 'जिला'}  <input className={`col-md-3 form-control ${styles.lineInput}`} name='farmerDistrict' value={formik.values.farmerDistrict} disabled={true}/>,
                            {language === 'english' ? 'in the year' : 'को वर्ष'} <input className={`col-md-3 form-control ${styles.lineInput}`} value={new Date().getFullYear()} disabled={true}/> 
                            
                            {language === 'english' ? 'to do cultivation by lease at my land with Patta/ Khasra No.' : 'में निम्नलिखित उन्नत कृषि पद्धतियों के लिए पट्टा/खसरा संख्या'}
                            <div className={`col-md-3 form-control ${styles.lineInput}`}>
                            <Select
                            className={`${formik.touched.khasraNumbers && formik.errors.khasraNumbers ? styles.formInputError : ''}`}
                            id="khasraNumbers"
                            closeMenuOnSelect={false}
                            components={animatedComponents}
                            isMulti
                            placeholder = "Select Khasra Numbers"
                            onChange={(event) => selectHandler(event)}
                            options={transformedArray}
                            />
                            </div> {language  === 'english' ? 'with an area  of (in acre)' : 'के क्षेत्रफल (एकड़ में)'} <input className={`col-md-3 form-control ${styles.lineInput} ${formik.touched.area && formik.errors.area ? styles.formInputError : ''}`} type="number" min={0.00001} step='any' name='area' value={formik.values.area} onChange={formik.handleChange} onBlur={formik.handleBlur}/>
                            {
                                language === 'english' ? `With this agreement, it has been agreed that all carbon credits generated from this enrolled land shall be registered in the name of BharatRohan to sell to the third-party buyer. BharatRohan shall have all the rights, title and interest to such carbon credits upon generation.`
                                        : `वाली मेरी भूमि पर कृषि कार्य करने की अनुमति प्रदान ${formik.values.ownerRelation === 'son' ? 'करता' : formik.values.ownerRelation === '' ? 'करता/करती' : 'करती'} हूं। यह समझौता इस बात की पुष्टि करता है कि इस नामांकित भूमि से उत्पन्न सभी 
                                    कार्बन क्रेडिट भारतरोहण के नाम से पंजीकृत किए जाएंगे ताकि उन्हें किसी तीसरे पक्ष के खरीदार को बेचा जा सके। कार्बन क्रेडिट के निर्माण के बाद से ही उन पर सभी अधिकार, 
                                    शीर्षक और हित भारतरोहण के पास निहित हो जाएंगे|`
                            }
                            
                       
                        </p>

                        <br/>

                        <p>{language === 'english' ? 'Signature of Landowner' : 'भूस्वामी के हस्ताक्षर' }  - 
                            <select className={`col-md-2 form-control ${styles.lineInput} ${formik.touched.ownerSignType && formik.errors.ownerSignType ? styles.formInputError : ''}`} name='ownerSignType' value={formik.values.ownerSignType} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                                <option value=''>Select Signature type</option>
                                <option value='signature-pad'>Signature Pad</option>
                                <option value='sign-manually'>Sign By Name</option>
                            </select>
                        </p>
                      
                        
                        {
                            formik.values.ownerSignType === 'signature-pad' ? 
                            <div className=' row col-md-4'>
                                <ESignature signatureHandler={(uri) => formik.setFieldValue('ownerSign', uri)} />
                                { formik.values.ownerSign != '' ? <PreviewSignature uri={formik.values.ownerSign}/> : null }
                            </div> 
                             : formik.values.ownerSignType === 'sign-manually' ? <p className={styles.cedarvilleCursiveRegular}>{formik.values.ownerName.toLowerCase()}</p> : null
                        }
                        
                        <p>{language === 'english' ? 'Date' : 'दिनांक' } - <input 
                        className={`col-md-3 form-control ${styles.lineInput} ${formik.touched.ownerDate && formik.errors.ownerDate ? styles.formInputError : ''}`} 
                        type="date" name='ownerDate' 
                        value={formik.values.ownerDate}
                        min="2024-02-01"
                        max="2024-05-31"
                        onChange={formik.handleChange} onBlur={formik.handleBlur}/></p>
                        <br/>
                        
                        <p>{language === 'english' ? 'Accepted by' : 'स्वीकृतकर्ता' }, </p>
                        <p>{language === 'english' ? 'Signature of Farmer' : 'खेती करने वाले किसान के हस्ताक्षर' } - 
                            <select className={`col-md-2 form-control ${styles.lineInput} ${formik.touched.farmerSignType && formik.errors.farmerSignType ? styles.formInputError : ''}`} name='farmerSignType' value={formik.values.farmerSignType}
                            onChange={formik.handleChange} onBlur={formik.handleBlur}>
                                <option value=''>Select Signature type</option>
                                <option value='signature-pad'>Signature Pad</option>
                                <option value='sign-manually'>Sign By Name</option>
                            </select>
                        </p> 
                       
                        {
                            formik.values.farmerSignType === 'signature-pad' ? 
                            <div className=' row col-md-4'>
                                <ESignature signatureHandler={(uri) => formik.setFieldValue('farmerSign', uri)}/> 
                                { formik.values.farmerSign != '' ? <PreviewSignature uri={formik.values.farmerSign}/> : null }
                            </div> : formik.values.farmerSignType === 'sign-manually' ? <p className={styles.cedarvilleCursiveRegular}>{formik.values.farmerName.toLowerCase()}</p> : null
                        }
                        <p>{language === 'english' ? 'Date' : 'दिनांक' } - 
                            <input className={`col-md-3 form-control ${styles.lineInput} ${formik.touched.farmerDate && formik.errors.farmerDate ? styles.formInputError : ''}`} 
                            type="date" name='farmerDate' 
                            value={formik.values.farmerDate} 
                            onChange={formik.handleChange}
                            min="2024-02-01"
                            max="2024-05-31" 
                            onBlur={formik.handleBlur}/></p>
                            <button className="btn btn-secondary" type="submit" disabled={disabled}>{disabled === true ? 'Submitting...' : 'Submit'}</button> &nbsp;
                            <button className="btn btn-danger" type="button" onClick={() => remove()}>Cancel</button>
                    </form>
                    
                </div>
    </div>
  )
}

export default LeaseAgreementSign;